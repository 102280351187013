import * as React from "react";
import * as Yup from "yup";
import { LocalDialog, Spacer, SkuSelector } from "components";
import { useLocalDialog } from "hooks";
import { useDebounce } from "use-debounce";
import { Dispatch, SetStateAction, useEffect } from "react";
import {
	Formik,
	Form,
	ErrorMessage,
	FieldArray,
	useFormikContext
} from "formik";
import { FormGroup, Input, Label } from "reactstrap";
import { useMutation, useQuery } from "react-query";
import {
	AccurateService,
	CommonService,
	CustomerService,
	HubService,
	InventoryService,
	OrderService
} from "api/axios";
import Select from "react-select";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
// import { MuiFileInput } from "mui-file-input";
import { formatIDR } from "helper";
import style from "./QuotationCreate.module.scss";
import { FileEarmarkRichtext } from "react-bootstrap-icons";
import AccurateCustomerSelector from "views/finance/payment/AccurateCustomerSelector";
import { useAppSelector } from "store/hooks";

interface IAddress {
	id?: string;
	postalId?: number;
	name?: string;
	lat?: string;
	lon?: string;
	line1?: string;
	line2?: string;
}

const Developers = [
	{
		valueStr: "N/A",
		labelStr: "N/A"
	},
	{
		valueStr: "Sinarmas Land",
		labelStr: "Sinarmas Land"
	},
	{
		valueStr: "Summarecon",
		labelStr: "Summarecon"
	},
	{
		valueStr: "Deltamas",
		labelStr: "Deltamas"
	},
	{
		valueStr: "CGST",
		labelStr: "CGST"
	},
	{
		valueStr: "Paramount",
		labelStr: "Paramount"
	},
	{
		valueStr: "Modernland",
		labelStr: "Modernland"
	},
	{
		valueStr: "Grand Wisata",
		labelStr: "Grand Wisata"
	},
	{
		valueStr: "Lippo Cikarang",
		labelStr: "Lippo Cikarang"
	}
];

const createSphSchema = Yup.object().shape({
	batchingPlantId: Yup.object().shape({
		valueStr: Yup.string().required("Lokasi Batching Plant Dibutuhkan"),
		labelStr: Yup.string().required("Lokasi Batching Plant Dibutuhkan")
	}),
	customer: Yup.object().shape({
		valueStr: Yup.string().required("Nama Pelanggan Dibutuhkan"),
		labelStr: Yup.string().required("Nama Pelanggan Dibutuhkan")
	}),
	projectId: Yup.object().shape({
		valueStr: Yup.string().required("Nama Proyek Dibutuhkan"),
		labelStr: Yup.string().required("Nama Proyek Dibutuhkan")
	}),
	requestedProducts: Yup.array()
		.of(
			Yup.object().shape({
				name: Yup.string().required("Produk harus dipilih"),
				quantity: Yup.number()
					.typeError("Volume harus berupa angka")
					.required("Volume harus diisi")
					.positive("Volume harus lebih dari 0"),
				offeringPrice: Yup.number()
					.typeError("Harga harus berupa angka")
					.required("Harga harus diisi")
					.positive("Harga harus lebih dari 0"),
				pouringMethod: Yup.string().required(
					"Metode Penuangan harus dipilih"
				),
				skuSpecifications: Yup.object().shape({
					"concrete-grade-a": Yup.string().required(
						"Concrete Grade A harus dipilih"
					),
					location: Yup.string().required("Lokasi harus dipilih"),
					"slump-b": Yup.string().required("Slump B harus dipilih"),
					"pricing-range": Yup.string().required(
						"Pricing Range harus dipilih"
					),
					"concrete-grade-b": Yup.string().required(
						"Concrete Grade B harus dipilih"
					),
					"cementitious-component": Yup.string().required(
						"Cementitious Component harus dipilih"
					),
					"aggregate-combination": Yup.string(),
					"slump-a": Yup.string().required("Slump A harus dipilih"),
					"casted-element": Yup.string().required(
						"Casted Element harus dipilih"
					),
					"product-type": Yup.string().required(
						"Tipe Produk harus dipilih"
					),
					"pouring-method": Yup.string()
				}),
				skuId: Yup.string().uuid(),
				sku: Yup.string()
			})
		)
		.min(1, "Minimal 1 produk harus ditambahkan"),
	sales: Yup.object().shape({
		valueStr: Yup.string().required("Sales harus dipilih"),
		labelStr: Yup.string().required("Sales harus dipilih")
	}),
	customerDeveloper: Yup.object().shape({
		valueStr: Yup.string().required("Developer harus dipilih"),
		labelStr: Yup.string().required("Developer harus dipilih")
	}),
	paymentTerm: Yup.object().shape({
		valueStr: Yup.string().required("Syarat Pembayaran harus dipilih"),
		labelStr: Yup.string().required("Syarat Pembayaran harus dipilih")
	}),
	needPriceApproval: Yup.object().shape({
		valueStr: Yup.string().required("Perlu Approval Harga harus dipilih"),
		labelStr: Yup.string().required("Perlu Approval Harga harus dipilih")
	}),
	mobilizationFee: Yup.object().shape({
		valueStr: Yup.string().required(
			"Dikenakan Biaya Mobilisasi harus dipilih"
		),
		labelStr: Yup.string().required(
			"Dikenakan Biaya Mobilisasi harus dipilih"
		)
	}),
	mobilizationOption: Yup.object().shape({
		valueStr: Yup.string().required("Syarat Mobilisasi harus dipilih"),
		labelStr: Yup.string().required("Syarat Mobilisasi harus dipilih")
	}),
	description: Yup.string(),
	accurateTaxable: Yup.boolean(),
	accurateInclusiveTax: Yup.boolean(),
	customerAccurate: Yup.object().required("Pelanggan Accurate harus diisi")
});

const createSphSchemaNoAccurate = Yup.object().shape({
	batchingPlantId: Yup.object().shape({
		valueStr: Yup.string().required("Lokasi Batching Plant Dibutuhkan"),
		labelStr: Yup.string().required("Lokasi Batching Plant Dibutuhkan")
	}),
	customer: Yup.object().shape({
		valueStr: Yup.string().required("Nama Pelanggan Dibutuhkan"),
		labelStr: Yup.string().required("Nama Pelanggan Dibutuhkan")
	}),
	projectId: Yup.object().shape({
		valueStr: Yup.string().required("Nama Proyek Dibutuhkan"),
		labelStr: Yup.string().required("Nama Proyek Dibutuhkan")
	}),
	sales: Yup.object().shape({
		valueStr: Yup.string().required("Sales harus dipilih"),
		labelStr: Yup.string().required("Sales harus dipilih")
	}),
	requestedProducts: Yup.array()
		.of(
			Yup.object().shape({
				name: Yup.string().required("Produk harus dipilih"),
				quantity: Yup.number()
					.typeError("Volume harus berupa angka")
					.required("Volume harus diisi")
					.positive("Volume harus lebih dari 0"),
				offeringPrice: Yup.number()
					.typeError("Harga harus berupa angka")
					.required("Harga harus diisi")
					.positive("Harga harus lebih dari 0"),
				pouringMethod: Yup.string().required(
					"Metode Penuangan harus dipilih"
				),
				skuSpecifications: Yup.object().shape({
					"concrete-grade-a": Yup.string().required(
						"Concrete Grade A harus dipilih"
					),
					location: Yup.string().required("Lokasi harus dipilih"),
					"slump-b": Yup.string().required("Slump B harus dipilih"),
					"pricing-range": Yup.string().required(
						"Pricing Range harus dipilih"
					),
					"concrete-grade-b": Yup.string().required(
						"Concrete Grade B harus dipilih"
					),
					"cementitious-component": Yup.string().required(
						"Cementitious Component harus dipilih"
					),
					"aggregate-combination": Yup.string(),
					"slump-a": Yup.string().required("Slump A harus dipilih"),
					"casted-element": Yup.string().required(
						"Casted Element harus dipilih"
					),
					"product-type": Yup.string().required(
						"Tipe Produk harus dipilih"
					),
					"pouring-method": Yup.string()
				}),
				skuId: Yup.string().uuid(),
				sku: Yup.string()
			})
		)
		.min(1, "Minimal 1 produk harus ditambahkan"),
	description: Yup.string()
});

const pouringMethodOptions = [
	{
		valueStr: "Manual",
		labelStr: "Manual"
	},
	{
		valueStr: "Concrete Pump",
		labelStr: "Concrete Pump"
	}
];

const mobilizationOptions = [
	{
		valueStr: "Total Pengiriman di Hari H <4m3",
		labelStr: "Total Pengiriman di Hari H <4m3"
	},
	{
		valueStr: "Free Mobilisasi Berdasarkan Pengajuan Sales",
		labelStr: "Free Mobilisasi Berdasarkan Pengajuan Sales"
	},
	{
		valueStr: "N/A",
		labelStr: "N/A"
	}
];

const mobilizationFee = [
	{
		valueStr: "Iya",
		labelStr: "Iya"
	},
	{
		valueStr: "Tidak",
		labelStr: "Tidak"
	}
];

export default function CreateQuotation({
	isOpen,
	setIsOpen,
	refetch
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	refetch: () => void;
}) {
	// const { setFieldValue } = useFormikContext();
	const { localDialog } = useLocalDialog();
	// const [uploadFile, setUploadFile] = React.useState<File>();
	const [nameCustomer, setNameCustomer] = React.useState<string>("");
	const [searchCustomerQ] = useDebounce(nameCustomer, 500);
	const [nameSales, setNameSales] = React.useState<string>("");
	const [searchSalesQ] = useDebounce(nameSales, 500);
	const [productSearchQuery, setProductSearchQuery] = React.useState("");
	const [optionProject, setOptionProject] = React.useState<any>([]);
	const [customerSelected, setCustomerSelected] =
		React.useState<ApiResponsePlain<CustomerDetailSelected> | null>(null);
	const [shippingAddressSelected, setShippingAddressSelected] =
		React.useState<any>(null);
	const [projectSelected, setProjectSelected] = React.useState<
		string | undefined
	>("");
	const [searchCustomer, setSearchCustomer] = React.useState("");

	const [searchCustomerDebounced] = useDebounce(searchCustomer, 500);
	const [batchingPlantSelected, setBatchingPlantSelected] = React.useState<{
		addressId: string;
		code: string;
		id?: string;
		name: string;
		createdAt: string;
		updatedAt: string;
	} | null>(null);
	const [additionalDistancePrice, setAdditionalDistancePrice] =
		React.useState(0);
	const [distance, setDistance] = React.useState<number | null>(null);
	const [requestedProductsSelected, setRequestedProductsSelected] =
		React.useState<
			{
				offeringPrice: number;
				name: string;
				displayName: string;
				quantity: number | null;
				pouringMethod: string;
				unit: string;
				labelStr: string;
				valueStr: string;
				calcPrice: number;
				Price: {
					price: number;
				};
				skuSpecifications: {
					"concrete-grade-a": string;
					location: string;
					"slump-b": string;
					"pricing-range": string;
					"concrete-grade-b": string;
					"cementitious-component": string;
					"aggregate-combination": string;
					"slump-a": string;
					"casted-element": string;
					"product-type": string;
					"pouring-method": string;
				};
				skuId: string;
				sku: string;
			}[]
		>([
			{
				offeringPrice: 0,
				name: "",
				displayName: "",
				pouringMethod: "",
				quantity: null,
				unit: "",
				labelStr: "",
				valueStr: "",
				calcPrice: 0,
				Price: {
					price: 0
				},
				skuSpecifications: {
					"concrete-grade-a": "",
					location: "",
					"slump-b": "2",
					"pricing-range": "S",
					"concrete-grade-b": "",
					"cementitious-component": "",
					"aggregate-combination": "A",
					"slump-a": "12",
					"casted-element": "",
					"product-type": "",
					"pouring-method": "B"
				},
				skuId: "",
				sku: ""
			}
		]);
	const { isAccurateDisabled: accurateStatusDisabled } = useAppSelector(
		(state) => state.setting
	);

	// accurate disabled
	const isAccurateDisabled =
		process.env.REACT_APP_IS_ACCURATE_DISABLED === "true" ||
		accurateStatusDisabled;

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (isOpen) {
			localDialog.onOpen();
		}
	}, [isOpen]);

	const { data: customers } = useQuery<any, ApiError>(
		["query-search-customer", searchCustomerQ],
		async () => {
			return await CommonService.getAllCustomer(1, 20, {
				search: searchCustomerQ
			});
		},
		{
			enabled: searchCustomerQ?.length ? true : false
		}
	);

	const { data: salesPeople } = useQuery<IUsers, ApiError>(
		["query-filter-sales", searchSalesQ],
		async () => {
			return await CommonService.getUsers(1, 99, "SALES", {
				search: searchSalesQ
			});
		},
		{
			enabled: isOpen
		}
	);

	const { data: batchingPlant } = useQuery<any, ApiError>(
		["query-batchingPlant", true],
		async () => {
			return await CommonService.getBP();
		},
		{
			enabled: true
		}
	);

	const { data: skuSpec } = useQuery<
		ApiResponsePlain<SkuSpecification[]>,
		ApiError
	>(
		["sku-specification", true],
		async () => {
			return await HubService.getSKUSpecification();
		},
		{
			enabled: true,
			onSuccess(data) {
				console.log(data.data, "ini data skuSpec");
			}
		}
	);

	const skuSpecLength = React.useMemo(() => {
		return (
			skuSpec?.data?.reduce((acc, el) => {
				return acc + +el.letterCount;
			}, 0) || 0
		);
	}, [skuSpec]);

	const { data: batchingPlantAddress } = useQuery<any, ApiError>(
		["query-batchingPlant-address", batchingPlantSelected?.addressId],
		async () => {
			if (!batchingPlantSelected?.addressId) {
				return null;
			}
			return await CommonService.getAddress(
				batchingPlantSelected.addressId
			);
		},
		{
			enabled: !!batchingPlantSelected
		}
	);
	//AccurateResponse
	const { data: branches, isLoading: branchLoading } = useQuery<
		ApiResponsePlain<AccurateResponse<Branch[]>>,
		ApiError
	>(
		["query-accurate-branch"],
		async () => {
			return AccurateService.getAccurateBranchApi({});
		},
		{
			enabled: true
		}
	);

	const { data: paymentTerms, isLoading: paymentTermsLoading } = useQuery<
		ApiResponsePlain<PaymentTerm[]>,
		ApiError
	>(
		["query-accurate-payment-term"],
		async () => {
			return AccurateService.getAccuratePaymentTermApi({});
		},
		{
			enabled: true
		}
	);

	const paymentTermOptions = React.useMemo(() => {
		if (paymentTerms?.data) {
			return paymentTerms?.data.map((el) => {
				return {
					valueStr: el.name,
					labelStr:
						el.name +
						(el.dueDescription ? `- (${el.dueDescription})` : "")
				};
			});
		}
		return [];
	}, [paymentTerms]);

	const { data: skuOrder } = useQuery<
		ApiResponsePlain<[keyof IskuSpec]>,
		ApiError
	>(
		["query-sku-order"],
		async () => {
			return await HubService.getSkuOrder();
		},
		{
			enabled: true
		}
	);

	const { data: customersAccurate, isLoading: customerListLoading } =
		useQuery<ApiResponsePlain<CustomerAccurate[]>>(
			["query-customer-accurate", searchCustomerDebounced],
			async () => {
				return await HubService.getCustomersAccurate({
					keywords: searchCustomerDebounced || ""
				});
			},
			{
				onError(err: any) {
					dispatch(
						openAlert({
							body: "message",
							color: "danger"
						})
					);
				},
				enabled: true
			}
		);

	const { data: detailAccurateCustomer } = useQuery<
		ApiResponsePlain<CustomerAccurate>
	>(
		[
			"query-customer-accurate-details",
			customerSelected?.data?.customerAccurateId
		],
		async () => {
			return await HubService.getDetailCustomerAccurate(
				customerSelected?.data?.customerAccurateId.toString() ?? ""
			);
		},
		{
			enabled: !!customerSelected?.data?.customerAccurateId
		}
	);

	const { data: allProducts, isLoading: isProductsLoading } = useQuery<
		any, //ApiResponsePagination<IProducts[]>  to make it typesafe i need to refactor the code........
		ApiError
	>(
		[
			"query-products",
			{
				batchingId: batchingPlantSelected?.id,
				projectId: projectSelected,
				search: productSearchQuery
			}
		],
		async () => {
			if (!projectSelected) {
				return [];
			}

			const productsList = await InventoryService.getAllProducts({
				batchingPlantId: batchingPlantSelected?.id,
				size: 20,
				page: 1,
				search: productSearchQuery
			});
			if (productsList.data.length > 0) {
				productsList.products = [...productsList.data];
				delete productsList.data;
			}

			const batchingPlantsAdditionalPrices =
				await CustomerService.getAdditionalPricePerBatchingPlant(
					projectSelected
				);

			const batchingPlantPrice = batchingPlantsAdditionalPrices.find(
				(batchingPlant) => {
					return batchingPlant.name === batchingPlantSelected?.name;
				}
			);

			setDistance(batchingPlantPrice?.distance || null);
			setAdditionalDistancePrice(
				batchingPlantPrice?.additionalPrice || 0
			);

			// TODO: save product[selectedIndex].Category.Parent.AdditionalPrices[correctAdditionPrice based on min max]
			// set id field distancePriceId to save in bulk requested products, and .price for totalAdditionalPrice

			return productsList;
		},
		{
			enabled: !!batchingPlantAddress && !!projectSelected
		}
	);

	const optionCustomer = React.useMemo(() => {
		if (customers?.data?.data?.length) {
			const data = customers?.data?.data.map((el: any) => {
				return {
					...el,
					valueStr: el.id,
					labelStr: el.displayName
				};
			});
			return data;
		}
		return [];
	}, [customers]);

	const optionSales = React.useMemo(() => {
		if (salesPeople?.data?.data?.length) {
			const data = salesPeople?.data?.data.map((el: any) => {
				return {
					...el,
					valueStr: el.id,
					labelStr: el.fullName
				};
			});

			return data;
		}
		return [];
	}, [salesPeople]);

	const optionProducts = React.useMemo(() => {
		if (allProducts?.products?.length) {
			let data = allProducts?.products?.map((el: any) => {
				return {
					...el,
					valueStr: el.id,
					labelStr: `${el.display_name} ${el.Category.name} - Slump ${el.properties?.slump}`
				};
			});
			data = data.sort((a: any, b: any) => {
				const nameA = a.labelStr.toUpperCase();
				const nameB = b.labelStr.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});
			return data;
		}
		return [];
	}, [allProducts]);

	const listBatchingPlant = React.useMemo(() => {
		if (batchingPlant?.data?.data?.length) {
			const data = batchingPlant?.data.data.map((el: any) => {
				return {
					...el,
					valueStr: el.id,
					labelStr: el.name
				};
			});
			return data;
		}
		return [];
	}, [batchingPlant]);

	const { mutateAsync: CreateQuotation } = useMutation(
		async (data: CreateQuotationModel) => {
			const res = await OrderService.createQuotation(data);
			return res;
		}
	);

	const mappedSkuSpec: {
		name: string;
		option: { label: string; value: string; spec: string }[];
		key: string;
	}[] = React.useMemo(() => {
		if (skuSpec?.data && skuOrder?.data) {
			return skuOrder?.data.map((order: string) => {
				const spec = skuSpec.data.find((el) => el.name === order);
				if (spec) {
					const values = Object.keys(spec.values).map((key) => {
						return {
							value: key,
							label: spec.values[key] || "",
							spec: spec.name
						};
					});

					return {
						name: spec?.displayName || "",
						option: values,
						key: spec.name
					};
				} else {
					return {
						name: "",
						option: [],
						key: ""
					};
				}
			});
		}
		return [];
	}, [skuSpec, skuOrder]);

	const defaultAccurateCustomer = React.useMemo(() => {
		if (!detailAccurateCustomer || !detailAccurateCustomer.data) {
			return undefined;
		}

		return {
			mobilePhone: detailAccurateCustomer.data.mobilePhone,
			name: detailAccurateCustomer.data.name,
			id: detailAccurateCustomer.data.id,
			whatsappNo: detailAccurateCustomer.data.whatsappNo,
			customerNo: detailAccurateCustomer.data.customerNo,
			email: detailAccurateCustomer.data.email
		} as CustomerAccurate;
	}, [detailAccurateCustomer]);

	// const { mutateAsync: uploadFileAws } = useMutation<
	// 	ApiResponseFiles,
	// 	ApiError,
	// 	FormData,
	// 	ApiError
	// >(async (_files: FormData) => {
	// 	return await CommonService.uploadFile(_files);
	// });

	// async function uploadQuotationDoc() {
	// 	try {
	// 		const _file = new FormData();
	// 		_file.append("photos", uploadFile as File);
	// 		_file.append("name", "SIGNED_SO");
	// 		const response = await uploadFileAws(_file);
	// 		const data = handleResponseFile(response.data, [
	// 			uploadFile as File
	// 		]);
	// 		const payload = data.map((el) => ({
	// 			...el,
	// 			type: "LETTER"
	// 		}));
	// 		return payload;
	// 	} catch (error) {
	// 		dispatch(
	// 			openAlert({
	// 				body: "Gagal upload SPH",
	// 				color: "danger"
	// 			})
	// 		);
	// 		return null;
	// 	}
	// }

	const labelStyle = {
		// marginBottom: "1em", // Adjust this value as needed
		marginTop: "1em" // Adjust this value as needed
	};

	const skuPreview = React.useCallback(
		(skuSpecifications: IskuSpec) => {
			const preview = (skuOrder?.data as [keyof IskuSpec])?.map((el) => {
				return skuSpecifications?.[el] as string;
			});
			const previewStr = preview?.join("");
			if (skuSpecLength === previewStr?.length) {
				return previewStr;
			}

			return "-";
		},
		[skuOrder?.data, skuSpec]
	);

	return (
		<LocalDialog
			isOpen={localDialog.isOpen}
			backdrop={"static"}
			header="Buat SPH Baru"
			onClose={() => {
				localDialog.onClose();
				setTimeout(() => {
					setIsOpen(false);
				}, 500);
			}}
		>
			<Formik
				initialValues={{
					customer: {
						labelStr: "",
						valueStr: ""
					},
					billingAddress: "",
					shippingAddress: "",
					paymentType: "",
					projectId: {
						labelStr: "",
						valueStr: ""
					},
					// projectDocs: {
					// 	labelStr: "",
					// 	valueStr: ""
					// },
					purchaseOrderId: {
						labelStr: "",
						valueStr: ""
					},
					quotationLetterId: {
						labelStr: "",
						valueStr: ""
					},
					batchingPlantId: {
						labelStr: "",
						valueStr: ""
					},
					distance: 0,
					delivery: {
						labelStr: "",
						valueStr: ""
					},
					isProvideBankGuarantee: false,
					viaTol: false,
					status: "SUBMITTED",
					paymentTerm: {
						labelStr: "",
						valueStr: ""
					},
					needPriceApproval: {
						labelStr: "",
						valueStr: ""
					},
					mobilizationFee: {
						labelStr: "",
						valueStr: ""
					},
					mobilizationOption: {
						labelStr: "",
						valueStr: ""
					},
					customerDeveloper: {
						labelStr: "",
						valueStr: ""
					},
					description: "",
					accurateTaxable: false,
					accurateInclusiveTax: false,
					requestedProducts: [
						{
							offeringPrice: 0,
							Price: {
								price: 0
							},
							name: "",
							displayName: "",
							pouringMethod: "",
							quantity: null,
							unit: "",
							labelStr: "",
							valueStr: "",
							calcPrice: 0,
							skuSpecifications: {
								"concrete-grade-a": "",
								location: "",
								"slump-b": "2",
								"pricing-range": "S",
								"concrete-grade-b": "",
								"cementitious-component": "",
								"aggregate-combination": "A",
								"slump-a": "12",
								"casted-element": "",
								"product-type": "",
								"pouring-method": "B"
							},
							skuId: "",
							sku: ""
						}
					],
					sales: {
						labelStr: "",
						valueStr: ""
					},
					quotationFile: null,
					customerAccurate: null as CustomerAccurate | null
				}}
				validationSchema={
					isAccurateDisabled
						? createSphSchemaNoAccurate
						: createSphSchema
				}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					try {
						const requestedProducts = values?.requestedProducts.map(
							(requestedProduct) => ({
								productId: requestedProduct.valueStr,
								offeringPrice: requestedProduct.offeringPrice,
								name: requestedProduct.name,
								quantity: requestedProduct.quantity,
								pouringMethod: requestedProduct.pouringMethod,
								unit: requestedProduct.unit,
								skuId: requestedProduct.skuId || undefined,
								sku: requestedProduct.skuId
									? requestedProduct.sku
									: requestedProduct.sku + "00",
								specifications:
									requestedProduct.skuSpecifications
							})
						);

						const selectedBranch = branches?.data?.d?.find(
							(branch) => {
								return values.batchingPlantId.labelStr
									.toLowerCase()
									.replace(/[\s_]+/g, "")
									.includes(
										branch.name
											.toLowerCase()
											.replace(/[\s_]+/g, "")
									);
							}
						);

						const billingAddress = {
							id: customerSelected?.data.BillingAddress?.id,
							lat: customerSelected?.data.BillingAddress
								?.lat as string,
							lon: customerSelected?.data.BillingAddress
								?.lon as string,
							line1: customerSelected?.data.BillingAddress?.line1,
							line2: customerSelected?.data.BillingAddress?.line2,
							// name: ??
							postalId:
								customerSelected?.data.BillingAddress?.Postal
									?.id
						};

						const payload = {
							isProvideBankGuarantee:
								values?.isProvideBankGuarantee,
							paymentType: values?.paymentType,
							projectId: values?.projectId?.valueStr,
							// projectDocs: values?.projectDocs?.valueStr,
							shippingAddress: shippingAddressSelected,
							billingAddress,
							viaTol: values.viaTol,
							batchingPlantId: values?.batchingPlantId?.valueStr,
							requestedProducts,
							totalAdditionalPrice: additionalDistancePrice,
							distance: distance,
							accurateBranchId: selectedBranch?.id,
							accurateTaxable: values.accurateTaxable,
							accurateInclusiveTax: values.accurateInclusiveTax,
							accuratePaymentTerm: values.paymentTerm.valueStr,
							accurateNeedPriceApproval:
								values.needPriceApproval.valueStr,
							charField2: values.mobilizationFee.valueStr,
							charField3: values.mobilizationOption.valueStr,
							charField4: values.customerDeveloper.valueStr,
							customerAccurateId: values.customerAccurate?.id
						} as CreateQuotationModel;

						const res = await CreateQuotation(payload);

						// if (uploadFile) {
						// 	const [uploadDocPayload] = await Promise.all([
						// 		uploadQuotationDoc()
						// 	]);

						// 	if (uploadDocPayload) {
						// 		await OrderService.putQuotation(
						// 			res.id,
						// 			uploadDocPayload
						// 		);
						// 	}
						// }

						setIsOpen(false);
						dispatch(
							openAlert({
								body: res.message,
								color: "success"
							})
						);
						refetch();
					} catch (error) {
						dispatch(
							openAlert({
								body: "Gagal menambah Quotation baru",
								color: "danger"
							})
						);
					}
				}}
			>
				{({
					handleChange,
					isSubmitting,
					values,
					setFieldValue,
					errors
				}) => {
					return (
						<Form
							style={{
								width: "70vw"
							}}
						>
							<FormGroup>
								<Label style={labelStyle}>
									Pilih Batching Plant *
								</Label>
								<Select
									onChange={(change) => {
										if (change) {
											values.batchingPlantId.labelStr =
												change.labelStr as string;
											values.batchingPlantId.valueStr =
												change.valueStr as string;
											setFieldValue("batchingPlantId", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
												// lat: change.lat,
												// lon: change.lon,
											});
											setBatchingPlantSelected({
												addressId: change.addressId,
												code: change.code,
												id: change.id,
												name: change.name,
												createdAt: change.createdAt,
												updatedAt: change.updatedAt
											});
											const bpName = change.name
												.split("-")[1]
												.replace(/[\s_]+/g, "");
											const locationOptions =
												mappedSkuSpec.find((el) => {
													return (
														el.key === "location"
													);
												});
											const found =
												locationOptions?.option.find(
													(el) => {
														return (
															el.label
																.toUpperCase()
																.replace(
																	/[\s_]+/g,
																	""
																) ===
															bpName.toUpperCase()
														);
													}
												);

											if (found) {
												setFieldValue(
													"requestedProducts[0].skuSpecifications.location",
													found.value
												);
											}
										}
									}}
									name="batchingPlantId"
									value={values.batchingPlantId}
									options={listBatchingPlant}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.valueStr as string
									}
									isDisabled={false} // Field pertama selalu aktif
								/>
								<ErrorMessage
									name={"batchingPlantId.labelStr"}
									component="div"
									className="error-msg"
								/>

								<Label
									style={labelStyle}
									htmlFor="searchCustomer"
								>
									Pelanggan *
								</Label>
								<Select
									onInputChange={(text) => {
										setNameCustomer(text);
									}}
									onChange={async (change) => {
										if (change) {
											const paymentType =
												change.Accounts[0]?.type;
											const billingAddress =
												change.billingAddressId;

											setFieldValue("customer", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
											setFieldValue(
												"paymentType",
												paymentType
											);
											setFieldValue(
												"billingAddress",
												billingAddress
											);

											setFieldValue("projectId", {
												labelStr: "",
												valueStr: ""
											});
											setFieldValue("saleOrderId", {
												labelStr: "",
												valueStr: ""
											});
											setDistance(null);

											setRequestedProductsSelected([
												{
													offeringPrice: 0,
													name: "",
													displayName: "",
													quantity: 0,
													pouringMethod: "",
													unit: "",
													labelStr: "",
													valueStr: "",
													calcPrice: 0,
													Price: {
														price: 0
													},
													skuSpecifications: {
														"concrete-grade-a": "",
														location: values
															.requestedProducts[0]
															?.skuSpecifications
															.location as string,
														"slump-b": "2",
														"pricing-range": "S",
														"concrete-grade-b": "",
														"cementitious-component":
															"",
														"aggregate-combination":
															"A",
														"slump-a": "12",
														"casted-element": "",
														"product-type": "",
														"pouring-method": "B"
													},
													skuId: "",
													sku: ""
												}
											]);
											setFieldValue("requestedProducts", [
												{
													offeringPrice: 0,
													name: "",
													displayName: "",
													quantity: 0,
													pouringMethod: "",
													unit: "",
													labelStr: "",
													valueStr: "",
													calcPrice: 0,
													skuSpecifications: {
														"concrete-grade-a": "",
														location: values
															.requestedProducts[0]
															?.skuSpecifications
															.location as string,
														"slump-b": "2",
														"pricing-range": "S",
														"concrete-grade-b": "",
														"cementitious-component":
															"",
														"aggregate-combination":
															"A",
														"slump-a": "12",
														"casted-element": "",
														"product-type": "",
														"pouring-method": "B"
													}
												}
											]);

											const projOpt = change.Projects.map(
												(el: any) => {
													return {
														...el,
														valueStr: el.id,
														labelStr: el.name
														// shipp
													};
												}
											);

											setOptionProject(projOpt);

											const customer =
												await CommonService.getOneCustomer(
													change?.valueStr as string
												);

											setCustomerSelected(customer);

											const foundMf =
												mobilizationFee.find(
													(tax) =>
														tax.valueStr ===
														customer?.data
															?.accurateData
															?.charField2
												) || {
													valueStr: "",
													labelStr: ""
												};
											const foundMo =
												mobilizationOptions.find(
													(tax) =>
														tax.valueStr ===
														customer?.data
															?.accurateData
															?.charField3
												) || {
													valueStr: "",
													labelStr: ""
												};

											const foundPaymentTerm =
												paymentTermOptions.find(
													(el) =>
														el.valueStr ===
														customer?.data
															?.accurateData
															?.termName
												);

											setFieldValue(
												"paymentTerm",
												foundPaymentTerm
											);

											setFieldValue(
												"mobilizationFee",
												foundMf
											);
											setFieldValue(
												"mobilizationOption",
												foundMo
											);

											values.customer.labelStr =
												change?.labelStr as string;
											values.customer.valueStr =
												change?.valueStr as string;
										}
									}}
									name="customer"
									value={values.customer}
									options={optionCustomer}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.labelStr as string
									}
									noOptionsMessage={() =>
										"Ketik Nama Customer"
									}
									isDisabled={false}
								/>
								<ErrorMessage
									name={"customer.labelStr"}
									component="div"
									className="error-msg"
								/>
								{isAccurateDisabled ? null : (
									<div
										style={{
											...labelStyle,
											marginBottom: "-1.5em"
										}}
									>
										<AccurateCustomerSelector
											customers={
												customersAccurate?.data || []
											}
											setSearchCustomer={
												setSearchCustomer
											}
											setFieldValue={setFieldValue}
											values={values}
											errors={errors}
											isLoading={customerListLoading}
											defaultAccurateCustomer={
												defaultAccurateCustomer
											}
											isDisabled={
												customerSelected?.data &&
												!defaultAccurateCustomer?.id
													? false
													: true
											}
										/>
										<p
											style={{
												fontSize: "11px",
												color: "lightgrey",
												marginTop: "-1em"
											}}
										>
											*Pilih Pelanggan terlebih dahulu
											sebelum memilih Pelanggan Accurate
										</p>
									</div>
								)}
								<Label style={labelStyle} htmlFor="Project">
									Proyek *
								</Label>
								<Select
									onChange={(change) => {
										if (change) {
											setFieldValue("projectId", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});

											setProjectSelected(change.valueStr);

											const project =
												customerSelected?.data?.Projects?.find(
													(customerProject: {
														[key: string]: any;
													}) => {
														return (
															customerProject.id ===
															change.valueStr
														);
													}
												);

											const shippingAddress = {
												id: project?.ShippingAddress
													?.id,
												lat: project?.ShippingAddress
													?.lat,
												lon: project?.ShippingAddress
													?.lon,
												line1: project?.ShippingAddress
													?.line1,
												line2: project?.ShippingAddress
													?.line2,
												// name: ??
												postalId:
													project?.ShippingAddress
														?.Postal?.id
											};

											setShippingAddressSelected(
												shippingAddress
											);
											setRequestedProductsSelected([
												{
													offeringPrice: 0,
													name: "",
													displayName: "",
													quantity: 0,
													pouringMethod: "",
													unit: "",
													labelStr: "",
													valueStr: "",
													calcPrice: 0,
													Price: {
														price: 0
													},
													skuSpecifications: {
														"concrete-grade-a": "",
														location: values
															.requestedProducts[0]
															?.skuSpecifications
															.location as string,
														"slump-b": "2",
														"pricing-range": "S",
														"concrete-grade-b": "",
														"cementitious-component":
															"",
														"aggregate-combination":
															"A",
														"slump-a": "12",
														"casted-element": "",
														"product-type": "",
														"pouring-method": "B"
													},
													skuId: "",
													sku: ""
												}
											]);
											setFieldValue("requestedProducts", [
												{
													offeringPrice: 0,
													name: "",
													displayName: "",
													quantity: 0,
													pouringMethod: "",
													unit: "",
													labelStr: "",
													valueStr: "",
													calcPrice: 0,
													skuSpecifications: {
														"concrete-grade-a": "",
														location: values
															.requestedProducts[0]
															?.skuSpecifications
															.location as string,
														"slump-b": "2",
														"pricing-range": "S",
														"concrete-grade-b": "",
														"cementitious-component":
															"",
														"aggregate-combination":
															"A",
														"slump-a": "12",
														"casted-element": "",
														"product-type": "",
														"pouring-method": "B"
													},
													skuId: "",
													sku: ""
												}
											]);
											setFieldValue("projectId", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
										}
									}}
									name="projectId"
									value={values.projectId}
									options={optionProject}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.valueStr as string
									}
									noOptionsMessage={() => "Ketik Nama Proyek"}
									isDisabled={
										values.customer.valueStr ? false : true
									}
								/>
								<ErrorMessage
									name={"projectId.labelStr"}
									component="div"
									className="error-msg"
								/>

								{/* Developer dropdown */}
								<Label style={labelStyle}>Developer *</Label>
								<Select
									name="customerDeveloper"
									value={values.customerDeveloper}
									options={Developers}
									defaultValue={Developers.find(
										(dev) =>
											dev.labelStr ===
											customerSelected?.data?.accurateData
												?.charField4
									)}
									getOptionLabel={(option) =>
										option.labelStr as string
									}
									getOptionValue={(option) =>
										option.valueStr as string
									}
									onChange={(change) => {
										if (change) {
											setFieldValue("customerDeveloper", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
										}
									}}
								/>
								<ErrorMessage
									name={"customerDeveloper.labelStr"}
									component="div"
									className="error-msg"
								/>

								{isAccurateDisabled ? null : (
									<div>
										<Label style={labelStyle}>
											Pilih Syarat Pembayaran *
										</Label>
										<Select
											name="paymentTerm"
											value={values.paymentTerm}
											options={paymentTermOptions}
											getOptionLabel={(option) =>
												option.labelStr as string
											}
											getOptionValue={(option) =>
												option.valueStr as string
											}
											onChange={(change) => {
												if (change) {
													setFieldValue(
														"paymentTerm",
														{
															labelStr:
																change.labelStr,
															valueStr:
																change.valueStr
														}
													);
												}
											}}
										/>
										<ErrorMessage
											name={"paymentTerm.labelStr"}
											component="div"
											className="error-msg"
										/>
									</div>
								)}

								<Label style={labelStyle}>
									Perlu Approval Harga *
								</Label>
								<Select
									name="needPriceApproval"
									value={values.needPriceApproval}
									options={[
										{
											valueStr: "Iya",
											labelStr: "Iya"
										},
										{
											valueStr: "Tidak",
											labelStr: "Tidak"
										}
									]}
									getOptionLabel={(option) =>
										option.labelStr as string
									}
									getOptionValue={(option) =>
										option.valueStr as string
									}
									onChange={(change) => {
										if (change) {
											setFieldValue("needPriceApproval", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
										}
									}}
								/>
								<ErrorMessage
									name={"needPriceApproval.labelStr"}
									component="div"
									className="error-msg"
								/>

								<Label style={labelStyle}>
									Dikenakan Biaya Mobilisasi? *
								</Label>
								<Select
									name="mobilizationFee"
									value={values.mobilizationFee}
									options={mobilizationFee}
									getOptionLabel={(option) =>
										option.labelStr as string
									}
									getOptionValue={(option) =>
										option.valueStr as string
									}
									onChange={(change) => {
										if (change) {
											setFieldValue("mobilizationFee", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
										}
									}}
									isDisabled={
										values.customer?.valueStr ? false : true
									}
								/>
								<ErrorMessage
									name={"mobilizationFee.labelStr"}
									component="div"
									className="error-msg"
								/>

								<Label style={labelStyle}>
									Syarat Mobilisasi *
								</Label>
								<Select
									name="mobilizationOption"
									value={values.mobilizationOption}
									options={mobilizationOptions}
									getOptionLabel={(option) =>
										option.labelStr as string
									}
									getOptionValue={(option) =>
										option.valueStr as string
									}
									onChange={(change) => {
										if (change) {
											setFieldValue(
												"mobilizationOption",
												{
													labelStr: change.labelStr,
													valueStr: change.valueStr
												}
											);
										}
									}}
									isDisabled={
										values.customer?.valueStr ? false : true
									}
								/>
								<ErrorMessage
									name={"mobilizationOption.labelStr"}
									component="div"
									className="error-msg"
								/>

								<Label style={labelStyle}>Info Pajak </Label>
								{/* Sub judul */}
								{/* Info Pajak */}
								<div
									style={{
										display: "flex",
										flexDirection: "column"
									}}
								>
									{/* Contents */}
									<div
										style={{
											display: "flex",
											flexDirection: "column"
										}}
									>
										<div
											style={{
												marginTop: "1rem"
											}}
										>
											<div
												style={{
													display: "flex",
													gap: "2rem",
													marginLeft: "1.3rem"
												}}
											>
												<Label check>
													<Input
														type="checkbox"
														checked={
															values.accurateTaxable
														}
														name="accurateTaxable"
														onChange={handleChange}
													/>{" "}
													Kena Pajak{" "}
												</Label>

												<Label check>
													<Input
														type="checkbox"
														checked={
															values.accurateInclusiveTax
														}
														name="accurateInclusiveTax"
														onChange={handleChange}
													/>{" "}
													Total Termasuk Pajak
												</Label>
											</div>
										</div>
									</div>
								</div>
								{/* <Select
								name="paymentTerm"
								value={values.needPriceApproval}
								options={[
									{
										valueStr: "Iya",
										labelStr: "Iya"
									},
									{
										valueStr: "Tidak",
										labelStr: "Tidak"
									}
								]}
								getOptionLabel={(option) =>
									option.labelStr as string
								}
								getOptionValue={(option) =>
									option.valueStr as string
								}
								onChange={(change) => {
									if (change) {
										setFieldValue("needPriceApproval", {
											labelStr: change.labelStr,
											valueStr: change.valueStr
										});
									}
								}}
							/> */}

								<Label style={labelStyle} htmlFor="searchSales">
									Sales *
								</Label>
								<Select
									onInputChange={(text) => {
										setNameSales(text);
									}}
									onChange={(change) => {
										if (change) {
											setFieldValue("sales", {
												labelStr: change.labelStr,
												valueStr: change.valueStr
											});
											values.sales.labelStr =
												change?.labelStr as string;
											values.sales.valueStr =
												change?.valueStr as string;
										}
									}}
									name="sales"
									value={values.sales}
									options={optionSales}
									getOptionLabel={(option: options) =>
										option.labelStr as string
									}
									getOptionValue={(option: options) =>
										option.labelStr as string
									}
									noOptionsMessage={() => "Ketik Nama Sales"}
									isDisabled={false}
								/>
								<ErrorMessage
									name={"sales.labelStr"}
									component="div"
									className="error-msg"
								/>

								<Label style={labelStyle}>Produk *</Label>
								<FieldArray name="requestedProducts">
									{({ remove, push, replace }) => (
										<div>
											{requestedProductsSelected?.map(
												(product, index) => (
													<div
														key={index}
														className=""
													>
														<div className="modal-fieldarray">
															<div
																style={{
																	width: "40%"
																}}
															>
																<Label
																	htmlFor={`requestedProducts.${index}.name`}
																	style={{
																		fontSize:
																			"0.8rem"
																	}}
																>
																	Nama
																</Label>
																<Select
																	className="modal-fieldarray-field"
																	name={`requestedProducts.${index}.name`}
																	onInputChange={(
																		text
																	) => {
																		setProductSearchQuery(
																			text
																		);
																	}}
																	onChange={(
																		change
																	) => {
																		if (
																			change
																		) {
																			const newProduct =
																				{
																					...product,
																					name: change.name,
																					valueStr:
																						change.valueStr,
																					labelStr:
																						change.labelStr,
																					offeringPrice:
																						change
																							.Price
																							.price +
																						additionalDistancePrice,
																					quantity: 1,
																					skuId: "",
																					sku: ""
																				};

																			replace(
																				index,
																				newProduct
																			);
																			const quality =
																				{
																					FC: "F",
																					K: "K",
																					FS: "S",
																					Class: "C"
																				};

																			const cementitious =
																				{
																					FA: "A",
																					NFA: "B"
																				};
																			const splittedName =
																				change.labelStr.split(
																					" "
																				);
																			const productCategory =
																				(
																					change as any
																				)
																					?.Category
																					?.name;
																			const productSpec =
																				splittedName[0]?.split(
																					"-"
																				);
																			const productQuality =
																				productSpec?.[0] as keyof typeof quality;
																			const productStrength =
																				productSpec?.[1];

																			const skuSpec =
																				splittedName[1] !==
																					"FA" &&
																				splittedName[1] !==
																					"NFA"
																					? splittedName[1]
																					: undefined;

																			const productType =
																				mappedSkuSpec.find(
																					(
																						sku
																					) => {
																						return (
																							sku.key ===
																							"product-type"
																						);
																					}
																				);

																			const type =
																				productType?.option.find(
																					(
																						el
																					) => {
																						if (
																							!skuSpec
																						) {
																							return el.label.includes(
																								"Standard"
																							);
																						}

																						return el.label.includes(
																							skuSpec
																						);
																					}
																				);
																			setFieldValue(
																				`requestedProducts.${index}.skuSpecifications`,
																				{
																					"concrete-grade-a":
																						quality[
																							productQuality
																						] ||
																						"",
																					location:
																						values
																							.requestedProducts[0]
																							?.skuSpecifications
																							.location as string,
																					"slump-b":
																						"2",
																					"pricing-range":
																						"S",
																					"concrete-grade-b":
																						productStrength?.padStart(
																							3,
																							"0"
																						) ||
																						"",
																					"cementitious-component":
																						cementitious[
																							productCategory as
																								| "FA"
																								| "NFA"
																						] ||
																						"",
																					"aggregate-combination":
																						"A",
																					"slump-a":
																						"12",
																					"casted-element":
																						"",
																					"product-type":
																						type?.value ||
																						"A",
																					"pouring-method":
																						"B"
																				}
																			);
																			// modify setRequestedProducts

																			const newProducts =
																				[
																					...requestedProductsSelected
																				];
																			newProducts[
																				index
																			] =
																				newProduct;
																			setRequestedProductsSelected(
																				newProducts
																			);
																		}
																	}}
																	options={
																		optionProducts
																	}
																	getOptionLabel={(
																		option: any
																	) =>
																		option.labelStr as string
																	}
																	getOptionValue={(
																		option: any
																	) =>
																		option.name as string
																	}
																	isDisabled={
																		values
																			.batchingPlantId
																			.valueStr &&
																		values
																			.projectId
																			.valueStr &&
																		batchingPlantAddress
																			? false
																			: true
																	}
																	value={
																		requestedProductsSelected[
																			index
																		]
																	}
																/>
																<ErrorMessage
																	name={`requestedProducts.${index}.name`}
																	component="div"
																	className="error-msg"
																/>
															</div>

															<div
																style={{
																	width: "10%"
																}}
															>
																<Label
																	style={{
																		fontSize:
																			"0.8rem"
																	}}
																	htmlFor={`requestedProducts.${index}.quantity`}
																>
																	Volume
																</Label>
																<Input
																	name={`requestedProducts.${index}.quantity`}
																	type="number"
																	onChange={(
																		e
																	) => {
																		handleChange(
																			e
																		);

																		const newProduct =
																			{
																				...product,
																				quantity:
																					e
																						.target
																						.value
																						? parseInt(
																								e
																									.target
																									.value
																						  )
																						: null
																			};
																		setFieldValue(
																			`requestedProducts.${index}.quantity`,
																			e
																				.target
																				.value
																		);
																		// replace(
																		// 	index,
																		// 	newProduct
																		// );
																		// modify setRequestedProducts

																		const newProducts =
																			[
																				...requestedProductsSelected
																			];

																		newProducts[
																			index
																		] =
																			newProduct;

																		setRequestedProductsSelected(
																			newProducts
																		);
																	}}
																	value={
																		product.quantity ||
																		""
																	}
																	style={{
																		height: "38px"
																	}}
																	disabled={
																		values
																			.batchingPlantId
																			.valueStr &&
																		values
																			.projectId
																			.valueStr &&
																		batchingPlantAddress
																			? false
																			: true
																	}
																/>
																<ErrorMessage
																	name={`requestedProducts.${index}.quantity`}
																	component="div"
																	className="error-msg"
																/>
															</div>

															<div
																style={{
																	flexGrow: 1
																}}
															>
																<Label
																	htmlFor={`requestedProducts.${index}.offeringPrice`}
																	style={{
																		fontSize:
																			"0.8rem"
																	}}
																>
																	Harga/m³
																</Label>
																<Input
																	name={`requestedProducts.${index}.offeringPrice`}
																	type="number"
																	onChange={(
																		e
																	) => {
																		handleChange(
																			e
																		);

																		const newProduct =
																			{
																				...product,
																				offeringPrice:
																					parseInt(
																						e
																							.target
																							.value
																					)
																			};

																		// replace(
																		// 	index,
																		// 	newProduct
																		// );
																		// modify setRequestedProducts

																		setFieldValue(
																			`requestedProducts.${index}.offeringPrice`,
																			e
																				.target
																				.value
																		);

																		const newProducts =
																			[
																				...requestedProductsSelected
																			];

																		newProducts[
																			index
																		] =
																			newProduct;

																		setRequestedProductsSelected(
																			newProducts
																		);
																	}}
																	value={
																		product.offeringPrice
																	}
																	style={{
																		height: "38px",
																		fontSize:
																			"14px"
																	}}
																	disabled={
																		values
																			.batchingPlantId
																			.valueStr &&
																		values
																			.projectId
																			.valueStr &&
																		batchingPlantAddress
																			? false
																			: true
																	}
																/>
																<ErrorMessage
																	name={`requestedProducts.${index}.offeringPrice`}
																	component="div"
																	className="error-msg"
																/>
															</div>

															<div
																// style={{
																// 	flex: 1,
																// 	display: "flex",
																// 	flexDirection: "column"
																// }}
																style={{
																	width: "20%"
																}}
															>
																<Label
																	style={{
																		fontSize:
																			"0.8rem"
																	}}
																>
																	Metode
																	Penuangan
																</Label>
																<Select
																	name={`requestedProducts.${index}.pouringMethod`}
																	styles={{
																		control:
																			(
																				provided
																			) => ({
																				...provided,
																				fontSize:
																					"14px"
																			})
																	}}
																	onChange={(
																		change
																	) => {
																		if (
																			change
																		) {
																			// const newProduct =
																			// 	{
																			// 		...product,
																			// 		pouringMethod:
																			// 			change.valueStr
																			// 	};
																			// replace(
																			// 	index,
																			// 	newProduct
																			// );
																			setFieldValue(
																				`requestedProducts.${index}.pouringMethod`,
																				change.valueStr
																			);
																		}
																	}}
																	options={
																		pouringMethodOptions
																	}
																	getOptionLabel={(
																		option: options
																	) =>
																		option.labelStr as string
																	}
																	getOptionValue={(
																		option: options
																	) =>
																		option.valueStr as string
																	}
																	placeholder={
																		"Metode"
																	}
																	isDisabled={
																		values
																			.batchingPlantId
																			.valueStr &&
																		values
																			.projectId
																			.valueStr &&
																		batchingPlantAddress
																			? false
																			: true
																	}
																/>
																<ErrorMessage
																	name={`requestedProducts.${index}.pouringMethod`}
																	component="div"
																	className="error-msg"
																/>
															</div>

															<LoadingButton
																disabled={
																	values
																		.batchingPlantId
																		.valueStr &&
																	values
																		.requestedProducts
																		.length >
																		1
																		? false
																		: true
																}
																onClick={() => {
																	remove(
																		index
																	);
																	const newProducts =
																		[
																			...requestedProductsSelected
																		];
																	newProducts.splice(
																		index,
																		1
																	);
																	setRequestedProductsSelected(
																		newProducts
																	);
																}}
															>
																Hapus
															</LoadingButton>
														</div>

														<div>
															<h3
																className={
																	style.specSkuTitle
																}
															>
																Spesifikasi SKU
															</h3>
															<p>
																preview SKU:{" "}
																<b>
																	{skuPreview(
																		values
																			?.requestedProducts?.[
																			index
																		]
																			?.skuSpecifications as IskuSpec
																	)}{" "}
																</b>
															</p>

															<div
																className={
																	style.specSkuContainer
																}
															>
																{mappedSkuSpec.map(
																	(
																		el,
																		indexSku
																	) => (
																		<div
																			key={
																				indexSku
																			}
																			className={
																				style.specSkuItem
																			}
																			style={
																				el.key ===
																				"location"
																					? {
																							display:
																								"none"
																					  }
																					: {}
																			}
																		>
																			<label>
																				{
																					el.name
																				}
																			</label>

																			<Select
																				options={
																					el.option
																				}
																				getOptionLabel={(
																					option
																				) => {
																					if (
																						el.name ===
																							"Kekuatan Mutu" &&
																						[
																							"F",
																							"S"
																						].includes(
																							values
																								?.requestedProducts[
																								index
																							]
																								?.skuSpecifications?.[
																								"concrete-grade-a" as keyof IskuSpec
																							] ||
																								""
																						)
																					) {
																						if (
																							!isNaN(
																								Number(
																									option.label
																								)
																							)
																						) {
																							return `${
																								Number(
																									option.label
																								) /
																								10
																							}`;
																						}
																					}
																					return option.label;
																				}}
																				getOptionValue={(
																					option
																				) => {
																					return option.value;
																				}}
																				isDisabled={
																					el.key !==
																						"location" &&
																					values
																						?.requestedProducts[
																						index
																					]
																						?.name
																						? false
																						: true
																				}
																				onChange={(
																					option
																				) => {
																					if (
																						option
																					) {
																						setFieldValue(
																							`requestedProducts.${index}.skuSpecifications.${el.key}`,
																							option.value
																						);
																					}
																				}}
																				value={{
																					label:
																						el.option.find(
																							(
																								el
																							) =>
																								el.value ===
																								values
																									?.requestedProducts[
																									index
																								]
																									?.skuSpecifications?.[
																									el.spec as keyof IskuSpec
																								]
																						)
																							?.label ||
																						"Pilih " +
																							el.name,
																					value:
																						values
																							?.requestedProducts[
																							index
																						]
																							?.skuSpecifications?.[
																							el.key as keyof IskuSpec
																						] ||
																						""
																				}}
																				placeholder={
																					"Pilih " +
																					el.name
																				}
																			/>

																			<ErrorMessage
																				name={`requestedProducts.${index}.skuSpecifications.${el.key}`}
																				component="div"
																				className="error-msg"
																			/>
																		</div>
																	)
																)}
																<div
																	className={
																		style.specSkuItem
																	}
																>
																	<SkuSelector
																		index={
																			index
																		}
																		setFieldValue={
																			setFieldValue
																		}
																		skuSpecLength={
																			skuSpecLength
																		}
																		skuSpec={
																			values
																				?.requestedProducts?.[
																				index
																			]
																				?.skuSpecifications
																		}
																		skuOrder={
																			skuOrder?.data
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												)
											)}

											{values.batchingPlantId
												.valueStr && (
												<LoadingButton
													onClick={() => {
														console.log(
															values.batchingPlantId,
															"values.batchingPlantId"
														);

														push({
															offeringPrice: 0,
															name: "",
															displayName: "",
															quantity: 0,
															pouringMethod: "",
															unit: "",
															labelStr: "",
															valueStr: "",
															skuSpecifications: {
																"concrete-grade-a":
																	"",
																location:
																	values
																		.requestedProducts[0]
																		?.skuSpecifications
																		?.location,
																"slump-b": "2",
																"pricing-range":
																	"S",
																"concrete-grade-b":
																	"",
																"cementitious-component":
																	"",
																"aggregate-combination":
																	"A",
																"slump-a": "12",
																"casted-element":
																	"",
																"product-type":
																	"",
																"pouring-method":
																	"B"
															}
														});
														//distance
														setRequestedProductsSelected(
															[
																...requestedProductsSelected,
																{
																	offeringPrice: 0,
																	name: "",
																	displayName:
																		"",
																	quantity: 0,
																	pouringMethod:
																		"",
																	unit: "",
																	labelStr:
																		"",
																	valueStr:
																		"",
																	calcPrice: 0,
																	Price: {
																		price: 0
																	},
																	skuSpecifications:
																		{
																			"concrete-grade-a":
																				"",
																			location:
																				values
																					.requestedProducts[0]
																					?.skuSpecifications
																					?.location ||
																				"",
																			"slump-b":
																				"2",
																			"pricing-range":
																				"",
																			"concrete-grade-b":
																				"",
																			"cementitious-component":
																				"",
																			"aggregate-combination":
																				"A",
																			"slump-a":
																				"12",
																			"casted-element":
																				"",
																			"product-type":
																				"",
																			"pouring-method":
																				"B"
																		},
																	skuId: "",
																	sku: ""
																}
															]
														);
													}}
												>
													Tambah produk
												</LoadingButton>
											)}
										</div>
									)}
								</FieldArray>
								<Spacer size="large" />
								<div>
									<p>
										<strong>
											Jarak dari Batching Plant ke Proyek:
										</strong>{" "}
										<span>
											{distance && !isProductsLoading
												? `${distance} KM`
												: isProductsLoading
												? "Loading..."
												: "-"}
										</span>
									</p>
									<p>
										<strong>Harga Jarak Tambahan:</strong>{" "}
										{additionalDistancePrice &&
										additionalDistancePrice ? (
											<>
												Rp{" "}
												{formatIDR(
													additionalDistancePrice
												)}
											</>
										) : (
											0
										)}
									</p>
									<p>
										<strong>Total Harga:</strong> Rp{" "}
										{formatIDR(
											(values.requestedProducts.length > 0
												? values.requestedProducts.reduce(
														(acc, curr) => {
															return (
																acc +
																curr.offeringPrice *
																	(curr.quantity ||
																		0)
															);
														},
														0
												  )
												: 0) +
												additionalDistancePrice *
													requestedProductsSelected.length
										)}
									</p>
								</div>
								<Spacer size="large" />
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "20px"
									}}
								></div>
							</FormGroup>

							<div className="modal-custom-footer">
								<LoadingButton
									color="error"
									onClick={() => {
										localDialog.onClose();
										setTimeout(() => {
											setIsOpen(false);
										});
									}}
									loading={isSubmitting}
									disabled={isSubmitting}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Batal
									</span>
								</LoadingButton>
								<LoadingButton
									color="error"
									variant="contained"
									type="submit"
									loading={isSubmitting}
									disabled={isSubmitting}
									sx={{
										backgroundColor: "red",
										color: "white"
									}}
								>
									<span
										style={{ textTransform: "capitalize" }}
									>
										Buat
									</span>
								</LoadingButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</LocalDialog>
	);
}
