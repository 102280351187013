import { InputNumberFormat } from "@react-input/number-format";
import {
	AccurateService,
	CommonService,
	HubService,
	OrderService
} from "api/axios";
import { LocalDialog, SkuSelector } from "components";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { useLocalDialog } from "hooks";
import React, { Fragment } from "react";
import * as Yup from "yup";
import { QueryObserverResult, useMutation, useQuery } from "react-query";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { openAlert } from "store/features/alert/AlertSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Select from "react-select";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import style from "./QuotationCreate.module.scss";
import AccurateCustomerSelector from "views/finance/payment/AccurateCustomerSelector";
import { useDebounce } from "use-debounce";
import axios from "axios";
// import { useDebounce } from "use-debounce";

const labelStyle = {
	marginTop: "1em"
};
const taxType = [
	{ valueStr: "BKN_PEMUNGUT_PPN", labelStr: "01 - Bukan Pemungut PPN" },
	{
		valueStr: "BESARAN_TERTENTU",
		labelStr: "05 - Besaran Tertentu (Pasal 9A ayat (1) UU PPN)"
	},
	{ valueStr: "DPP_NILAILAIN", labelStr: "04 - DPP Nilai Lain" },
	{ valueStr: "EXPBKP_TDKWJD", labelStr: "14 - Ekspor BKP Tidak Berwujud" },
	{ valueStr: "EXPBKP_WJD", labelStr: "13 - Ekspor BKP Berwujud" },
	{ valueStr: "EXP_JKP", labelStr: "15 - Ekspor JKP" },
	{ valueStr: "PAJAK_DIDEEMED", labelStr: "05 - Pajak di Deemed" },
	{
		valueStr: "PEMUNGUT_BENDAHARA_PEMERINTAH",
		labelStr: "02 - Pemungut Bendaharawan Pemerintah"
	},
	{ valueStr: "PEMUNGUT_PPN", labelStr: "03 - Pemungut Selain Bendaharawan" },
	{
		valueStr: "PENYERAHAN_ASSET",
		labelStr: "09 - Penyerahan Aset (Pasal 16D UU PPN)"
	},
	{
		valueStr: "PENYERAHAN_LAIN",
		labelStr:
			"06 - Penyerahan Lainnya, termasuk penyerahan kepada turis asing dalam rangka VAT refund"
	},
	{ valueStr: "PPN_DIBEBASKAN", labelStr: "08 - PPN Dibebaskan" },
	{ valueStr: "PPN_TDK_DIPUNGUT", labelStr: "07 - PPN Tidak Dipungut" }
];

const documentType = [
	{
		valueStr: "INVOICE",
		labelStr: "Faktur Pajak"
	},
	{
		valueStr: "EXPORT",
		labelStr: "Dokumen Ekspor (PEB)"
	},
	{
		valueStr: "DOCUMENT",
		labelStr: "Dokumen Dipersamakan FP"
	},
	{
		valueStr: "DIGUNGGUNG",
		labelStr: "Digunggung"
	}
];

const sourcLeadOptions = [
	{
		valueStr: "Website",
		labelStr: "Website"
	},
	{
		valueStr: "Tokopedia",
		labelStr: "Tokopedia"
	},
	{
		valueStr: "Shopee",
		labelStr: "Shopee"
	},
	{
		valueStr: "Direct Sales",
		labelStr: "Direct Sales"
	},
	{
		valueStr: "WATI",
		labelStr: "WATI"
	},
	{
		valueStr: "Agent",
		labelStr: "Agent"
	}
];

const pouringMethodOptions = [
	{
		valueStr: "Rigid",
		labelStr: "Rigid"
	},
	{
		valueStr: "Manual",
		labelStr: "Manual"
	},
	{
		valueStr: "Concrete Pump",
		labelStr: "Concrete Pump"
	}
];

const mobilizationOptions = [
	{
		valueStr: "Total Pengiriman di Hari H <4m3",
		labelStr: "Total Pengiriman di Hari H <4m3"
	},
	{
		valueStr: "Free Mobilisasi Berdasarkan Pengajuan Sales",
		labelStr: "Free Mobilisasi Berdasarkan Pengajuan Sales"
	},
	{
		valueStr: "N/A",
		labelStr: "N/A"
	}
];

const mobilizationFee = [
	{
		valueStr: "Iya",
		labelStr: "Iya"
	},
	{
		valueStr: "Tidak",
		labelStr: "Tidak"
	}
];

const Developers = [
	{
		valueStr: "N/A",
		labelStr: "N/A"
	},
	{
		valueStr: "Sinarmas Land",
		labelStr: "Sinarmas Land"
	},
	{
		valueStr: "Summarecon",
		labelStr: "Summarecon"
	},
	{
		valueStr: "Deltamas",
		labelStr: "Deltamas"
	},
	{
		valueStr: "CGST",
		labelStr: "CGST"
	},
	{
		valueStr: "Paramount",
		labelStr: "Paramount"
	},
	{
		valueStr: "Modernland",
		labelStr: "Modernland"
	},
	{
		valueStr: "Grand Wisata",
		labelStr: "Grand Wisata"
	},
	{
		valueStr: "Lippo Cikarang",
		labelStr: "Lippo Cikarang"
	}
];

const ApproveSchema = Yup.object().shape({
	paymentTerm: Yup.object().shape({
		valueStr: Yup.string().required("Syarat Pembayaran harus dipilih"),
		labelStr: Yup.string().required("Syarat Pembayaran harus dipilih")
	}),
	documentCodeName: Yup.object().shape({
		valueStr: Yup.string().required("Jenis Dokumen harus dipilih"),
		labelStr: Yup.string().required("Jenis Dokumen harus dipilih")
	}),
	customerTaxType: Yup.object().shape({
		valueStr: Yup.string().required("Jenis Transaksi harus dipilih"),
		labelStr: Yup.string().required("Jenis Transaksi harus dipilih")
	}),
	customerLimitAge: Yup.boolean(),
	customerLimitAgeValue: Yup.number().when("customerLimitAge", {
		is: true,
		then: (schema) => schema.required("Hari harus diisi")
	}),
	customerLimitAmount: Yup.boolean(),
	customerLimitAmountValue: Yup.string().when("customerLimitAmount", {
		is: true,
		then: (schema) => schema.required("Jumlah harus diisi")
	}),
	mobilizationFee: Yup.object().shape({
		valueStr: Yup.string().required(
			"Dikenakan Biaya Mobilisasi harus dipilih"
		),
		labelStr: Yup.string().required(
			"Dikenakan Biaya Mobilisasi harus dipilih"
		)
	}),
	mobilizationOption: Yup.object().shape({
		valueStr: Yup.string().required("Syarat Mobilisasi harus dipilih"),
		labelStr: Yup.string().required("Syarat Mobilisasi harus dipilih")
	}),
	requestedProducts: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required("Produk harus dipilih"),
			quantity: Yup.number()
				.typeError("Volume harus berupa angka")
				.required("Volume harus diisi")
				.positive("Volume harus lebih dari 0"),
			offeringPrice: Yup.number()
				.typeError("Harga harus berupa angka")
				.required("Harga harus diisi")
				.positive("Harga harus lebih dari 0"),
			pouringMethod: Yup.object().shape({
				valueStr: Yup.string().required(
					"Metode Penuangan harus dipilih"
				),
				labelStr: Yup.string().required(
					"Metode Penuangan harus dipilih"
				)
			}),
			specifications: Yup.object().shape({
				"concrete-grade-a": Yup.string().required(
					"Concrete Grade A harus dipilih"
				),
				location: Yup.string().required("Lokasi harus dipilih"),
				"slump-b": Yup.string().required("Slump B harus dipilih"),
				"pricing-range": Yup.string().required(
					"Pricing Range harus dipilih"
				),
				"concrete-grade-b": Yup.string().required(
					"Concrete Grade B harus dipilih"
				),
				"cementitious-component": Yup.string().required(
					"Cementitious Component harus dipilih"
				),
				"aggregate-combination": Yup.string(),
				"slump-a": Yup.string().required("Slump A harus dipilih"),
				"casted-element": Yup.string().required(
					"Casted Element harus dipilih"
				),
				"product-type": Yup.string().required(
					"Tipe Produk harus dipilih"
				),
				"pouring-method": Yup.string()
			}),
			skuId: Yup.string().uuid().optional(),
			sku: Yup.string()
		})
	),
	customerAccurate: Yup.object().required("Pelanggan Accurate harus diisi")
});

const ApproveSchemaNoAccurate = Yup.object().shape({
	requestedProducts: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required("Produk harus dipilih"),
			quantity: Yup.number()
				.typeError("Volume harus berupa angka")
				.required("Volume harus diisi")
				.positive("Volume harus lebih dari 0"),
			offeringPrice: Yup.number()
				.typeError("Harga harus berupa angka")
				.required("Harga harus diisi")
				.positive("Harga harus lebih dari 0"),
			pouringMethod: Yup.object().shape({
				valueStr: Yup.string().required(
					"Metode Penuangan harus dipilih"
				),
				labelStr: Yup.string().required(
					"Metode Penuangan harus dipilih"
				)
			}),
			specifications: Yup.object().shape({
				"concrete-grade-a": Yup.string().required(
					"Concrete Grade A harus dipilih"
				),
				location: Yup.string().required("Lokasi harus dipilih"),
				"slump-b": Yup.string().required("Slump B harus dipilih"),
				"pricing-range": Yup.string().required(
					"Pricing Range harus dipilih"
				),
				"concrete-grade-b": Yup.string().required(
					"Concrete Grade B harus dipilih"
				),
				"cementitious-component": Yup.string().required(
					"Cementitious Component harus dipilih"
				),
				"aggregate-combination": Yup.string(),
				"slump-a": Yup.string().required("Slump A harus dipilih"),
				"casted-element": Yup.string().required(
					"Casted Element harus dipilih"
				),
				"product-type": Yup.string().required(
					"Tipe Produk harus dipilih"
				),
				"pouring-method": Yup.string()
			}),
			skuId: Yup.string().uuid().optional(),
			sku: Yup.string()
		})
	),
	customerDeveloper: Yup.object().shape({
		valueStr: Yup.string().required("Developer harus dipilih"),
		labelStr: Yup.string().required("Developer harus dipilih")
	})
});

export default function ApproveQuotation({
	customerType,
	customerId,
	accurateData,
	refetch,
	batchingPlant,
	requestedProducts
}: {
	customerType?: "COMPANY" | "INDIVIDU";
	customerId: string;
	accurateData?: AccurateDataCustomer;
	requestedProducts?: QuotationProductModel[];
	refetch: () => Promise<
		QueryObserverResult<{ data: ApiQuotation }, ApiError>
	>;
	batchingPlant?: BatchingPlantModel;
}) {
	const { localDialog } = useLocalDialog();
	const dispatch = useAppDispatch();
	const params = useParams();
	const id = params?.id;

	const [searchCustomer, setSearchCustomer] = React.useState("");
	const [searchCustomerDebounced] = useDebounce(searchCustomer, 500);
	const [customerSelected, setCustomerSelected] =
		React.useState<ApiResponsePlain<CustomerDetailSelected> | null>();

	const { isAccurateDisabled: accurateStatusDisabled } = useAppSelector(
		(state) => state.setting
	);

	//accurate disable
	const isAccurateDisabled =
		process.env.REACT_APP_IS_ACCURATE_DISABLED === "true" ||
		accurateStatusDisabled;

	const { mutateAsync: updateQuotation, isLoading: loadingPut } = useMutation(
		async (payload: unknown) => {
			return await OrderService.putQuotation(id as string, payload);
		},
		{
			onSuccess: () => {
				refetch();
				localDialog.onClose();
			}
		}
	);

	const { data: paymentTerms, isLoading: paymentTermsLoading } = useQuery<
		ApiResponsePlain<PaymentTerm[]>,
		ApiError
	>(
		["query-accurate-payment-term"],
		async () => {
			return AccurateService.getAccuratePaymentTermApi({});
		},
		{
			enabled: true
		}
	);

	const { data: accurateEmployee } = useQuery<
		ApiResponsePlain<AccurateResponse<AccurateEmployee[]>>,
		ApiError
	>(
		["query-accurate-employee", accurateData?.salesmanNumber],
		async () => {
			return AccurateService.getAccurateEmployeeApi({
				keywords: accurateData?.salesmanNumber
			});
		},
		{
			enabled: true
		}
	);

	// const { data: customerAccurate } = useQuery<
	// 	ApiResponsePlain<CustomerAccurate[]>,
	// 	ApiError
	// >(
	// 	["query-accurate-customer", customerType],
	// 	async () => {
	// 		let keywords = "";
	// 		if (customerType === "COMPANY") {
	// 			keywords = "A02";
	// 		}
	// 		if (customerType === "INDIVIDU") {
	// 			keywords = "B02";
	// 		}
	// 		return AccurateService.getAccurateCustomerApi({
	// 			size: 1,
	// 			keywords: keywords
	// 		});
	// 	},
	// 	{
	// 		enabled: !!customerType
	// 	}
	// );

	const { data: skuOrder } = useQuery<
		ApiResponsePlain<[keyof IskuSpec]>,
		ApiError
	>(
		["query-sku-order"],
		async () => {
			return await HubService.getSkuOrder();
		},
		{
			enabled: true
		}
	);

	const { data: skuSpec } = useQuery<
		ApiResponsePlain<SkuSpecification[]>,
		ApiError
	>(
		["sku-specification", true],
		async () => {
			return await HubService.getSKUSpecification();
		},
		{
			enabled: true,
			onSuccess(data) {
				console.log(data.data, "ini data skuSpec");
			}
		}
	);

	// get one customer accurate data
	const { data: detailAccurateCustomer } = useQuery<
		ApiResponsePlain<CustomerAccurate>
	>(
		[
			"query-customer-accurate-details",
			customerSelected?.data?.customerAccurateId
		],
		async () => {
			return await HubService.getDetailCustomerAccurate(
				customerSelected?.data?.customerAccurateId.toString() ?? ""
			);
		},
		{
			enabled: !!customerSelected?.data?.customerAccurateId
		}
	);

	const defaultAccurateCustomer = React.useMemo(() => {
		if (!detailAccurateCustomer || !detailAccurateCustomer.data) {
			return undefined;
		}

		return {
			mobilePhone: detailAccurateCustomer.data.mobilePhone,
			name: detailAccurateCustomer.data.name,
			id: detailAccurateCustomer.data.id,
			whatsappNo: detailAccurateCustomer.data.whatsappNo,
			customerNo: detailAccurateCustomer.data.customerNo,
			email: detailAccurateCustomer.data.email
		} as CustomerAccurate;
	}, [detailAccurateCustomer]);

	const paymentTermOptions = React.useMemo(() => {
		if (paymentTerms?.data) {
			return paymentTerms?.data.map((el) => {
				return {
					valueStr: el.name,
					labelStr:
						el.name +
						(el.dueDescription ? `- (${el.dueDescription})` : "")
				};
			});
		}
		return [];
	}, [paymentTerms]);

	const employeeOptions = React.useMemo(() => {
		if (
			accurateEmployee?.data?.d &&
			Array.isArray(accurateEmployee?.data?.d)
		) {
			return accurateEmployee?.data?.d?.map((el) => {
				return {
					valueStr: el.number,
					labelStr: el.name
				};
			});
		}
		return [];
	}, [accurateEmployee]);

	const skuSpecLength = React.useMemo(() => {
		return (
			skuSpec?.data?.reduce((acc, el) => {
				return acc + +el.letterCount;
			}, 0) || 0
		);
	}, [skuSpec]);

	const mappedSkuSpec = React.useMemo(() => {
		if (skuSpec?.data && skuOrder?.data) {
			return skuOrder?.data.map((order: string) => {
				const spec = skuSpec.data.find((el) => el.name === order);
				if (spec) {
					const values = Object.keys(spec.values).map((key) => {
						return {
							value: key,
							label: spec.values[key] || "",
							spec: spec.name
						};
					});

					return {
						name: spec?.displayName || "",
						option: values,
						key: spec.name
					};
				} else {
					return {
						name: "",
						option: [],
						key: ""
					};
				}
			});
		}
		return [];
	}, [skuSpec, skuOrder]);

	// get one customer bod
	useQuery<ApiResponsePlain<CustomerDetailSelected>, ApiError>(
		["customer-detail", customerId],
		async () => {
			return await CommonService.getOneCustomer(customerId as string);
		},
		{
			enabled: true,
			onSuccess: (res) => {
				setCustomerSelected(res);
			},
			onError: (error) => {
				console.log(error);
			}
		}
	);

	const { data: customersAccurate, isLoading: customerListLoading } =
		useQuery<ApiResponsePlain<CustomerAccurate[]>>(
			["query-customer-accurate", searchCustomerDebounced],
			async () => {
				return await HubService.getCustomersAccurate({
					keywords: searchCustomerDebounced || ""
				});
			},
			{
				onError(err: any) {
					dispatch(
						openAlert({
							body: "message",
							color: "danger"
						})
					);
				},
				enabled: true
			}
		);

	const skuPreview = React.useCallback(
		(specifications: IskuSpec) => {
			const preview = (skuOrder?.data as [keyof IskuSpec])?.map((el) => {
				return specifications?.[el] as string;
			});
			const previewStr = preview?.join("");
			// if (skuSpecLength === previewStr?.length) {
			return previewStr;
			// }

			// return "-";
		},
		[skuOrder?.data, skuSpec]
	);

	const location = React.useMemo(() => {
		const locationOptions = mappedSkuSpec.find((el) => {
			return el.key === "location";
		});
		return locationOptions?.option.find((el) => {
			return (
				el.label.toLowerCase() ===
				batchingPlant?.name
					?.split("-")[1]
					?.replace(/[\s_]+/g, "")
					?.toLowerCase()
			);
		});
	}, [mappedSkuSpec, batchingPlant]);

	const quality = {
		FC: "F",
		K: "K",
		FS: "S",
		Class: "C"
	};

	const cementitious = {
		FA: "A",
		NFA: "B"
	};

	return (
		<Fragment>
			<Button
				size="sm"
				color="success"
				className="confirm-button approve-button"
				onClick={() => {
					localDialog.onOpen();
				}}
			>
				Approve
			</Button>
			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				header="Approve SPH"
				onClose={() => {
					localDialog.onClose();
				}}
			>
				{mappedSkuSpec.length ? (
					<Formik
						initialValues={{
							// accurateNumber: accurateData?.customerNo || "",
							paymentTerm:
								paymentTermOptions.find(
									(el) =>
										el.valueStr === accurateData?.termName
								) ||
								(paymentTermOptions[0] as
									| OptionLine
									| undefined),
							customerTaxType:
								taxType.find(
									(tax) =>
										tax.valueStr ===
										accurateData?.customerTaxType
								) || (taxType[0] as OptionLine | undefined),
							mobilizationFee: mobilizationFee.find(
								(tax) =>
									tax.valueStr === accurateData?.charField2
							) || {
								valueStr: "",
								labelStr: ""
							},
							mobilizationOption: mobilizationOptions.find(
								(tax) =>
									tax.valueStr === accurateData?.charField3
							) || {
								valueStr: "",
								labelStr: ""
							},
							customerDeveloper:
								Developers.find(
									(dev) =>
										dev.labelStr ===
										accurateData?.charField4
								) || (Developers[0] as OptionLine | undefined),
							documentCodeName:
								documentType.find(
									(doc) =>
										doc.valueStr ===
										accurateData?.documentCode
								) ||
								(documentType[0] as OptionLine | undefined),
							salesmanNumber:
								employeeOptions.find(
									(empl) =>
										empl.valueStr ===
										accurateData?.salesmanNumber
								) || (null as OptionLine | null),
							customerLimitAge:
								accurateData?.customerLimitAge || false, // customerLimitAge value
							customerLimitAgeValue:
								accurateData?.customerLimitAgeValue || "", // customerLimitAgeValue value
							customerLimitAmount:
								accurateData?.customerLimitAmount || false, // customerLimitAmount value
							customerLimitAmountValue:
								accurateData?.customerLimitAmountValue?.toString() ||
								"",
							leadSource:
								sourcLeadOptions.find(
									(lead) =>
										lead.valueStr ===
										accurateData?.charField1
								) || (null as OptionLine | null),
							requestedProducts:
								requestedProducts?.map((product) => {
									const splittedName =
										product.displayName.split(" ");
									const productSpec =
										splittedName[0]?.split("-");
									const productQuality =
										productSpec?.[0] as keyof typeof quality;
									const productStrength = productSpec?.[1];

									const productCategory =
										product.Product?.Category?.name;

									const skuSpec =
										splittedName[1] !== "FA" &&
										splittedName[1] !== "NFA"
											? splittedName[1]
											: undefined;
									const productType = mappedSkuSpec.find(
										(sku) => {
											return sku.key === "product-type";
										}
									);
									const type = productType?.option.find(
										(el) => {
											if (!skuSpec) {
												return el.label.includes(
													"Standard"
												);
											}

											return el.label.includes(skuSpec);
										}
									);

									const spec = {
										"concrete-grade-a":
											quality[productQuality] || "",
										location: location?.value || "",
										"slump-b": "2",
										"pricing-range": "S",
										"concrete-grade-b":
											productStrength?.padStart(3, "0") ||
											"",
										"cementitious-component":
											cementitious[
												productCategory as "FA" | "NFA"
											] || "",
										"aggregate-combination": "A",
										"slump-a": "12",
										"casted-element": "",
										"product-type": type?.value || "A",
										"pouring-method": "B"
									};

									return {
										offeringPrice: product.offeringPrice,
										name: product.name,
										displayName: product.displayName,
										quantity: product.quantity,
										// pouringMethod:  product.pouringMethod,
										pouringMethod:
											pouringMethodOptions.find(
												(op) =>
													op.labelStr ===
													product.pouringMethod
											),
										unit: product.unit,
										labelStr: product.displayName,
										valueStr: product.id,
										productId: product.Product.id,
										requestedProductId: product.id,
										calcPrice:
											product.offeringPrice *
											product.quantity,
										specifications: product.specifications
											? product.specifications
											: spec,
										skuId: "",
										sku: ""
									};
								}) || [],
							customerAccurate: null as CustomerAccurate | null
						}}
						onSubmit={async (values, { setSubmitting }) => {
							setSubmitting(true);
							try {
								//

								const payload = {
									accurateData: {
										salesmanNumber:
											values.salesmanNumber?.valueStr,
										// customerNo: values.accurateNumber,
										termName: values.paymentTerm?.valueStr,
										documentCode:
											values.documentCodeName?.valueStr,
										customerTaxType:
											values.customerTaxType?.valueStr,
										customerLimitAge:
											values.customerLimitAge,
										customerLimitAgeValue:
											typeof values.customerLimitAgeValue ===
											"number"
												? values.customerLimitAgeValue
												: undefined,
										customerLimitAmount:
											values.customerLimitAmount,
										customerLimitAmountValue: parseFloat(
											values.customerLimitAmountValue
												.replace(",00", "")
												.replace(/\D+/g, "")
										),
										charField1: values.leadSource?.valueStr,
										charField2:
											values.mobilizationFee?.valueStr,
										charField3:
											values.mobilizationOption?.valueStr,
										charField4:
											values.customerDeveloper?.valueStr
									},
									requestedProducts:
										values.requestedProducts.map((prod) => {
											return {
												id: prod.requestedProductId,
												offeringPrice:
													prod.offeringPrice,
												quantity: prod.quantity,
												specifications:
													prod.specifications,
												sku: prod.skuId
													? prod.sku
													: prod.sku + "00",
												productId: prod.productId,
												skuId: prod.skuId
											};
										}),
									customerAccurateId:
										values.customerAccurate?.id
								};

								console.log(values, "values");

								const res = await updateQuotation(payload);
								console.log(res, "resupdateQuotation");

								dispatch(
									openAlert({
										body: res?.message,
										color: "success"
									})
								);
							} catch (error) {
								console.log(error, "error approve quotation");

								let errorMessage = "Gagal Approve Quotation";

								if (axios.isAxiosError(error)) {
									const accurateError =
										error?.response?.data?.data?.error?.data?.errors?.join(
											" "
										);
									errorMessage =
										accurateError ||
										error?.response?.data?.data?.message ||
										error?.response?.data?.data?.error
											?.message ||
										"Unknown error";
								}

								dispatch(
									openAlert({
										body: errorMessage,
										color: "danger"
									})
								);
							}
						}}
						validationSchema={
							isAccurateDisabled
								? ApproveSchemaNoAccurate
								: ApproveSchema
						}
					>
						{({
							handleChange,
							values,
							errors,
							setFieldValue,
							isSubmitting
						}) => (
							<Form>
								<FormGroup>
									{/* Developer dropdown */}
									<Label style={labelStyle}>
										Developer *
									</Label>
									<Select
										name="customerDeveloper"
										value={values.customerDeveloper}
										options={Developers}
										getOptionLabel={(option) =>
											option.labelStr as string
										}
										getOptionValue={(option) =>
											option.valueStr as string
										}
										onChange={(change) => {
											if (change) {
												setFieldValue(
													"customerDeveloper",
													{
														labelStr:
															change.labelStr,
														valueStr:
															change.valueStr
													}
												);
											}
										}}
									/>
									<ErrorMessage
										name={"customerDeveloper.labelStr"}
										component="div"
										className="error-msg"
									/>
								</FormGroup>

								{isAccurateDisabled ? null : (
									<AccurateCustomerSelector
										customers={
											customersAccurate?.data || []
										}
										setSearchCustomer={setSearchCustomer}
										setFieldValue={setFieldValue}
										values={values}
										errors={errors}
										isLoading={customerListLoading}
										defaultAccurateCustomer={
											defaultAccurateCustomer
										}
										// isDisabled={
										// 	customerSelected?.data &&
										// 	!defaultAccurateCustomer?.id
										// 		? false
										// 		: true
										// }
									/>
								)}

								{isAccurateDisabled ? null : (
									<div>
										<FormGroup>
											<Label style={labelStyle}>
												Pilih Syarat Pembayaran *
											</Label>
											{paymentTermOptions.length ? (
												<Select
													name="paymentTerm"
													value={values.paymentTerm}
													options={paymentTermOptions}
													getOptionLabel={(
														option
													) => {
														if (option) {
															return option.labelStr;
														}
														return "";
													}}
													getOptionValue={(
														option
													) => {
														if (option) {
															return option.valueStr as string;
														}
														return "";
													}}
													onChange={(change) => {
														if (change) {
															setFieldValue(
																"paymentTerm",
																{
																	labelStr:
																		change.labelStr,
																	valueStr:
																		change.valueStr
																}
															);
														}
													}}
													defaultValue={
														paymentTermOptions.find(
															(el) =>
																el.valueStr ===
																accurateData?.termName
														) || undefined
													}
												/>
											) : null}

											<ErrorMessage
												name={"paymentTerm.labelStr"}
												component="div"
												className="error-msg"
											/>
										</FormGroup>
									</div>
								)}

								<FormGroup>
									<Label style={labelStyle}>
										Jenis Dokumen *
									</Label>
									<Select
										name="documentCodeName"
										value={values.documentCodeName}
										options={documentType}
										getOptionLabel={(option) =>
											option.labelStr as string
										}
										getOptionValue={(option) =>
											option.valueStr as string
										}
										onChange={(change) => {
											if (change) {
												setFieldValue(
													"documentCodeName",
													{
														labelStr:
															change.labelStr,
														valueStr:
															change.valueStr
													}
												);
											}
										}}
										defaultValue={documentType.find(
											(el) =>
												el.valueStr ===
												accurateData?.documentCode
										)}
									/>
									<ErrorMessage
										name={"documentCodeName.labelStr"}
										component="div"
										className="error-msg"
									/>
								</FormGroup>

								<FormGroup>
									<Label style={labelStyle}>
										Jenis Transaksi *
									</Label>
									<Select
										name="customerTaxType"
										value={values.customerTaxType}
										options={taxType}
										getOptionLabel={(option) =>
											option.labelStr as string
										}
										getOptionValue={(option) =>
											option.valueStr as string
										}
										onChange={(change) => {
											if (change) {
												setFieldValue(
													"customerTaxType",
													{
														labelStr:
															change.labelStr,
														valueStr:
															change.valueStr
													}
												);
											}
										}}
									/>
									<ErrorMessage
										name={"customerTaxType.labelStr"}
										component="div"
										className="error-msg"
									/>
								</FormGroup>

								{isAccurateDisabled ? null : (
									<div>
										<FormGroup>
											<Label style={labelStyle}>
												Default Penjual (Accurate)
											</Label>
											<Select
												name="salesmanNumber"
												value={values.salesmanNumber}
												options={employeeOptions}
												getOptionLabel={(option) => {
													if (option) {
														return (
															option.labelStr +
															` - ${option.valueStr}`
														);
													}
													return "";
												}}
												getOptionValue={(option) => {
													if (option) {
														return option.valueStr as string;
													}
													return "";
												}}
												onChange={(change) => {
													if (change) {
														setFieldValue(
															"salesmanNumber",
															{
																labelStr:
																	change.labelStr,
																valueStr:
																	change.valueStr
															}
														);
													}
												}}
												defaultValue={employeeOptions.find(
													(el) =>
														el.valueStr ===
														accurateData?.salesmanNumber
												)}
											/>
											<ErrorMessage
												name={"salesmanNumber.labelStr"}
												component="div"
												className="error-msg"
											/>
										</FormGroup>
									</div>
								)}

								<FormGroup>
									<Label style={labelStyle}>
										Sumber Lead
									</Label>
									<Select
										name="leadSource"
										value={values.leadSource}
										options={sourcLeadOptions}
										getOptionLabel={(option) => {
											if (option) {
												return option.labelStr;
											}
											return "";
										}}
										getOptionValue={(option) => {
											if (option) {
												return option.valueStr as string;
											}
											return "";
										}}
										onChange={(change) => {
											if (change) {
												setFieldValue("leadSource", {
													labelStr: change.labelStr,
													valueStr: change.valueStr
												});
											}
										}}
									/>
									<ErrorMessage
										name={"leadSource.labelStr"}
										component="div"
										className="error-msg"
									/>
								</FormGroup>

								<FormGroup>
									<Label style={labelStyle}>
										Dikenakan Biaya Mobilisasi? *
									</Label>
									<Select
										name="mobilizationFee"
										value={values.mobilizationFee}
										options={mobilizationFee}
										getOptionLabel={(option) =>
											option.labelStr as string
										}
										getOptionValue={(option) =>
											option.valueStr as string
										}
										onChange={(change) => {
											if (change) {
												setFieldValue(
													"mobilizationFee",
													{
														labelStr:
															change.labelStr,
														valueStr:
															change.valueStr
													}
												);
											}
										}}
									/>
									<ErrorMessage
										name={"mobilizationFee.labelStr"}
										component="div"
										className="error-msg"
									/>
								</FormGroup>

								<FormGroup>
									<Label style={labelStyle}>
										Syarat Mobilisasi *
									</Label>
									<Select
										name="mobilizationOption"
										value={values.mobilizationOption}
										options={mobilizationOptions}
										getOptionLabel={(option) =>
											option.labelStr as string
										}
										getOptionValue={(option) =>
											option.valueStr as string
										}
										onChange={(change) => {
											if (change) {
												setFieldValue(
													"mobilizationOption",
													{
														labelStr:
															change.labelStr,
														valueStr:
															change.valueStr
													}
												);
											}
										}}
									/>
									<ErrorMessage
										name={"mobilizationOption.labelStr"}
										component="div"
										className="error-msg"
									/>
								</FormGroup>

								<FormGroup>
									<Label style={labelStyle}>
										Pembatasan Piutang Pelanggan
									</Label>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "1em",
											justifyContent: "space-between",
											marginBottom: "1em"
										}}
									>
										<div
											className="d-flex"
											style={{ gap: "1em" }}
										>
											<input
												type="checkbox"
												name="customerLimitAge"
												id="customerLimitAge"
												checked={
													values.customerLimitAge
												}
												onChange={handleChange}
											/>

											<div>
												<Label htmlFor="customerLimitAge">
													Jika ada faktur berumur
													lebih dari
												</Label>
												<ErrorMessage
													name={
														"customerLimitAgeValue"
													}
													component="div"
													className="error-msg"
												/>
											</div>
										</div>
										<div
											className="d-flex"
											style={{
												width: "35%",
												gap: "1em",
												alignItems: "center"
											}}
										>
											{values.customerLimitAge ? (
												<>
													<Input
														type="number"
														name="customerLimitAgeValue"
														onChange={handleChange}
														value={
															values.customerLimitAgeValue
														}
													/>
												</>
											) : (
												<div
													style={{
														backgroundColor:
															"lightgrey",
														textDecoration:
															"line-through"
													}}
													className="form-control"
												>
													{
														values.customerLimitAgeValue
													}
												</div>
											)}
											<p>Hari</p>
										</div>
									</div>

									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "1em",
											justifyContent: "space-between"
										}}
									>
										<div
											className="d-flex"
											style={{ gap: "1em" }}
										>
											<input
												type="checkbox"
												name="customerLimitAmount"
												id="customerLimitAmount"
												checked={
													values.customerLimitAmount
												}
												onChange={handleChange}
											/>
											<div>
												<Label htmlFor="customerLimitAmount">
													Jika total piutang & pesanan
													melebihi
												</Label>
												<ErrorMessage
													name={
														"customerLimitAmountValue"
													}
													component="div"
													className="error-msg"
												/>
											</div>
										</div>
										{values.customerLimitAmount ? (
											<>
												{/* <Input
												type="number"
												style={{
													width: "35%"
												}}
												name="customerLimitAmountValue"
												onChange={handleChange}
												value={
													values.customerLimitAmountValue
												}
											/> */}
												<InputNumberFormat
													locales="id-ID"
													format="currency"
													currency="IDR"
													name="customerLimitAmountValue"
													onChange={handleChange}
													value={
														values.customerLimitAmountValue
													}
													className="form-control"
													style={{
														width: "35%"
													}}
												/>
											</>
										) : (
											<div
												style={{
													width: "35%",
													backgroundColor:
														"lightgrey",
													textDecoration:
														"line-through"
												}}
												className="form-control"
											>
												{
													values.customerLimitAmountValue
												}
											</div>
										)}
									</div>
									<FieldArray name="requestedProducts">
										{() => (
											<div>
												{values.requestedProducts?.map(
													(product, index) => (
														<div
															key={index}
															className=""
														>
															<div className="modal-fieldarray">
																<div
																	style={{
																		width: "20%"
																	}}
																>
																	<Label
																		htmlFor={`requestedProducts.${index}.name`}
																		style={{
																			fontSize:
																				"0.8rem"
																		}}
																	>
																		Nama
																	</Label>
																	<Input
																		name={`requestedProducts.${index}.name`}
																		value={
																			values
																				.requestedProducts?.[
																				index
																			]
																				?.displayName
																		}
																		disabled
																	/>

																	<ErrorMessage
																		name={`requestedProducts.${index}.name`}
																		component="div"
																		className="error-msg"
																	/>
																</div>
																<div
																	style={{
																		width: "20%"
																	}}
																>
																	<Label
																		style={{
																			fontSize:
																				"0.8rem"
																		}}
																		htmlFor={`requestedProducts.${index}.quantity`}
																	>
																		Volume
																	</Label>
																	<Input
																		name={`requestedProducts.${index}.quantity`}
																		type="number"
																		onChange={(
																			e
																		) => {
																			handleChange(
																				e
																			);

																			setFieldValue(
																				`requestedProducts.${index}.quantity`,
																				e
																					.target
																					.value
																			);
																		}}
																		value={
																			product.quantity ||
																			""
																		}
																		style={{
																			height: "38px"
																		}}
																	/>
																	<ErrorMessage
																		name={`requestedProducts.${index}.quantity`}
																		component="div"
																		className="error-msg"
																	/>
																</div>
																<div
																	style={{
																		flexGrow: 1
																	}}
																>
																	<Label
																		htmlFor={`requestedProducts.${index}.offeringPrice`}
																		style={{
																			fontSize:
																				"0.8rem"
																		}}
																	>
																		Harga/m³
																	</Label>
																	<Input
																		name={`requestedProducts.${index}.offeringPrice`}
																		type="number"
																		onChange={(
																			e
																		) => {
																			handleChange(
																				e
																			);

																			setFieldValue(
																				`requestedProducts.${index}.offeringPrice`,
																				e
																					.target
																					.value
																			);
																		}}
																		value={
																			product.offeringPrice
																		}
																		style={{
																			height: "38px",
																			fontSize:
																				"14px"
																		}}
																	/>
																	<ErrorMessage
																		name={`requestedProducts.${index}.offeringPrice`}
																		component="div"
																		className="error-msg"
																	/>
																</div>
																<div
																	// style={{
																	// 	flex: 1,
																	// 	display: "flex",
																	// 	flexDirection: "column"
																	// }}
																	style={{
																		width: "30%"
																	}}
																>
																	<Label
																		style={{
																			fontSize:
																				"0.8rem"
																		}}
																	>
																		Metode
																		Penuangan
																	</Label>
																	<Select
																		name={`requestedProducts.${index}.pouringMethod`}
																		styles={{
																			control:
																				(
																					provided
																				) => ({
																					...provided,
																					fontSize:
																						"14px"
																				})
																		}}
																		onChange={(
																			change
																		) => {
																			if (
																				change
																			) {
																				// const newProduct =
																				// 	{
																				// 		...product,
																				// 		pouringMethod:
																				// 			change.valueStr
																				// 	};
																				// replace(
																				// 	index,
																				// 	newProduct
																				// );
																				setFieldValue(
																					`requestedProducts.${index}.pouringMethod`,
																					change
																				);
																			}
																		}}
																		options={
																			pouringMethodOptions
																		}
																		getOptionLabel={(
																			option: options
																		) =>
																			option.labelStr as string
																		}
																		getOptionValue={(
																			option: options
																		) =>
																			option.valueStr as string
																		}
																		placeholder={
																			"Metode"
																		}
																		value={
																			values
																				.requestedProducts[
																				index
																			]
																				?.pouringMethod
																		}
																		isOptionDisabled={(
																			option
																		) => {
																			return (
																				option.labelStr ===
																				"Rigid"
																			);
																		}}
																	/>

																	<ErrorMessage
																		name={`requestedProducts.${index}.pouringMethod.labelStr`}
																		component="div"
																		className="error-msg"
																	/>
																</div>
															</div>

															<div>
																<h3
																	className={
																		style.specSkuTitle
																	}
																>
																	Spesifikasi
																	SKU
																</h3>
																<p>
																	preview SKU:{" "}
																	<b>
																		{skuPreview(
																			values
																				?.requestedProducts?.[
																				index
																			]
																				?.specifications as IskuSpec
																		)}{" "}
																	</b>
																</p>

																<div
																	className={
																		style.specSkuContainer
																	}
																>
																	{mappedSkuSpec.map(
																		(
																			el,
																			indexSku
																		) => (
																			<div
																				key={
																					indexSku
																				}
																				className={
																					style.specSkuItem
																				}
																				// style={
																				// 	el.key ===
																				// 	"location"
																				// 		? {
																				// 				display:
																				// 					"none"
																				// 		  }
																				// 		: {}
																				// }
																			>
																				<label>
																					{
																						el.name
																					}
																				</label>

																				<Select
																					options={
																						el.option
																					}
																					getOptionLabel={(
																						option
																					) => {
																						if (
																							el.name ===
																								"Kekuatan Mutu" &&
																							[
																								"F",
																								"S"
																							].includes(
																								values
																									?.requestedProducts[
																									index
																								]
																									?.specifications?.[
																									"concrete-grade-a" as keyof IskuSpec
																								] ||
																									""
																							)
																						) {
																							if (
																								!isNaN(
																									Number(
																										option.label
																									)
																								)
																							) {
																								return `${
																									Number(
																										option.label
																									) /
																									10
																								}`;
																							}
																						}
																						return option.label;
																					}}
																					getOptionValue={(
																						option
																					) => {
																						return option.value;
																					}}
																					// isDisabled={
																					// 	el.key !==
																					// 		"location" &&
																					// 	values
																					// 		?.requestedProducts[
																					// 		index
																					// 	]
																					// 		?.name
																					// 		? false
																					// 		: true
																					// }
																					onChange={(
																						option
																					) => {
																						if (
																							option
																						) {
																							setFieldValue(
																								`requestedProducts.${index}.specifications.${el.key}`,
																								option.value
																							);
																						}
																					}}
																					value={{
																						label:
																							el.option.find(
																								(
																									el
																								) =>
																									el.value ===
																									values
																										?.requestedProducts[
																										index
																									]
																										?.specifications?.[
																										el.spec as keyof IskuSpec
																									]
																							)
																								?.label ||
																							"Pilih " +
																								el.name,
																						value:
																							values
																								?.requestedProducts[
																								index
																							]
																								?.specifications?.[
																								el.key as keyof IskuSpec
																							] ||
																							""
																					}}
																					placeholder={
																						"Pilih " +
																						el.name
																					}
																				/>

																				<ErrorMessage
																					name={`requestedProducts.${index}.specifications.${el.key}`}
																					component="div"
																					className="error-msg"
																				/>
																			</div>
																		)
																	)}
																	<div
																		className={
																			style.specSkuItem
																		}
																	>
																		<SkuSelector
																			index={
																				index
																			}
																			setFieldValue={
																				setFieldValue
																			}
																			skuSpecLength={
																				skuSpecLength
																			}
																			skuSpec={
																				values
																					?.requestedProducts?.[
																					index
																				]
																					?.specifications
																			}
																			skuOrder={
																				skuOrder?.data
																			}
																		/>
																	</div>
																</div>
															</div>
														</div>
													)
												)}
											</div>
										)}
									</FieldArray>
								</FormGroup>

								<div className="modal-custom-footer">
									<LoadingButton
										color="error"
										onClick={() => {
											localDialog.onClose();
											// setTimeout(() => {
											// 	// setIsOpen(false);
											// }, 500);
										}}
										loading={isSubmitting}
										disabled={isSubmitting}
									>
										<span
											style={{
												textTransform: "capitalize"
											}}
										>
											Batal
										</span>
									</LoadingButton>
									<LoadingButton
										color="success"
										variant="contained"
										type="submit"
										loading={isSubmitting}
										disabled={isSubmitting}
										sx={{
											color: "#fff",
											backgroundColor: "#2dce89",
											borderColor: "#2dce89",
											boxShadow:
												"0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)"
										}}
									>
										<span
											style={{
												textTransform: "capitalize"
											}}
										>
											Approve
										</span>
									</LoadingButton>
								</div>
							</Form>
						)}
					</Formik>
				) : null}
			</LocalDialog>
		</Fragment>
	);
}
